import React from 'react'
import { Accordion, Container, Col } from "react-bootstrap"
import parse from "html-react-parser"
import "./_faq.scss"
function FAQ({module}) {
  return (
    <section className='faq-section'>
        <Container className="faq-block-wrapper">
        <Col className="justify-content-center">
            <div className='head-faq-block'>
                <h4>faqs</h4>
                <h2>{module.title}</h2>
            </div>
            <div className="accordian-section">
                <Accordion flush>
                    {module.faq?.map((accordion, i) => (
                    <Accordion.Item eventKey={i} key={"acoordian" + i}>
                        <Accordion.Header>
                        <h4>{accordion.title}</h4>
                        </Accordion.Header>
                        <Accordion.Body>
                        {parse(accordion.description)}
                        </Accordion.Body>
                    </Accordion.Item>
                    ))}
                </Accordion>
                </div>
                </Col>
        </Container>
    </section>
  )
}

export default FAQ