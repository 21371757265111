import React from "react"
import Footer from "../components/Footer/Footer"
import Header from "../components/Header/Header"
import TileBlockModule from "../components/TileBlock/TileBlockModule"
import Banner from "../components/Banner/Banner"
import BeInspiredSlider from "../components/BeInspiredSlider/BeInspiredSlider"
import FAQ from "../components/FAQ/FAQ"
import FullWidthBanner from "../components/FullWidthBanner/FullWidthBanner"
import FeaturedPropertiesSlider from "../components/FeaturedPropertiesSlider/FeaturedPropertiesSlider"
import CareersListing from "../components/CareersListing/CareersListing"
import OurPeopleSlider from "../components/OurPeopleSlider/OurPeopleSlider"
import IconGridModule from "../components/IconGridModule/IconGridModule"
import TestimonialSlider from "../components/TestimonialSlider/TestimonialSlider"
import TestimonialCategorySlider from "../components/TestimonialSlider/TestimonialCategorySlider"
import HomeBannerSearch from "../components/HomeBannerSearch/HomeBannerSearch"
import BannerStatic from "../components/BannerStatic/BannerStatic"
import StaticImageWithText from "../components/StaticImageWithText/StaticImageWithText"
import StaticRichTextBlock from "../components/StaticRichTextBlock/StaticRichTextBlock"
import StaticBookValuation from "../components/StaticBookValuation/StaticBookValuation"
import AreaguideListing from "../components/AreaguideListing/AreaguideListing"
import TeamsListing from "../components/TeamsListing/TeamsListing"
import NewsListing from "../components/TeamsListing/NewsListing"
import BannerLandingSearch from "../components/BannerLandingSearch/BannerLandingSearch"
import LandingAboutModule from "../components/LandingAboutModule/LandingAboutModule"
import OtherAreaGuides from "../components/OtherAreaGuides/OtherAreaGuides"
import NewsSlider from "../components/NewsSlider/NewsSlider"
import GetInTouchBlock from "../components/GetInTouchBlock/GetInTouchBlock"
import NewsletterBanner from "../components/FullWidthBanner/NewsletterBanner"
import HeaderHomeMobile from "../components/Header/HeaderHomeMobile"

const CommonTemplate = props => {
  const GQLPage = props.GQLPage
  const GQLModules = props.GQLModules
  const bannerSection = GQLPage.banner_section

  return (
    <div className={`${GQLPage.page_class ? GQLPage.page_class : ""}`}>
      <Header layout={GQLPage.layout} />
      {GQLPage.layout === "landing_template" && (
        <Banner
          banner={bannerSection}
          id={GQLPage.id}
          slug={GQLPage.menu.slug}
          imagetransforms={GQLPage.imagetransforms}
        />
      )}
      {GQLPage.layout === "static_template" && (
        <BannerStatic
          banner={bannerSection}
          id={GQLPage.id}
          slug={GQLPage.menu.slug}
          page_class={GQLPage.page_class}
        />
      )}
      {GQLPage.layout === "landing_with_search_template" && (
        <BannerLandingSearch
          banner={bannerSection}
          bgImg={bannerSection.banner_image}
          title={bannerSection.title}
          id={GQLPage.id}
          slug={GQLPage.menu.slug}
          imagetransforms={GQLPage.imagetransforms}
          alias={GQLPage.alias}
          stats={bannerSection.Icon_Stats}
        />
      )}
      {GQLPage.alias === "home" && <HomeBannerSearch />}

      {GQLModules.map((module, i) => {
        return (
          <>
            {module.__typename ===
              "GLSTRAPI_ComponentComponentsTextBlockWithImage" &&
              (GQLPage.layout === "static_template" ? (
                <StaticImageWithText module={module} />
              ) : (
                <TileBlockModule module={module} />
              ))}

            {module.__typename ===
              "GLSTRAPI_ComponentComponentsSelectGlobalModules" &&
              module.Select_Module === "Be_Inspired_Section" && (
                <BeInspiredSlider module={module} />
              )}

            {module.__typename ===
              "GLSTRAPI_ComponentComponentsSelectGlobalModules" &&
              module.Select_Module === "Newsletter_Form_Banner" && (
                <NewsletterBanner module={module} />
              )}

            {/* {module.__typename === "GLSTRAPI_ComponentComponentsFaq" && (
              <FAQ module={module} />
            )} */}

            {module.__typename === "GLSTRAPI_ComponentBlocksRichTextBlock" && (
              <StaticRichTextBlock module={module} />
            )}
            {module.__typename ===
              "GLSTRAPI_ComponentComponentsAreaGuideContent" && (
              <LandingAboutModule
                aboutContent={module}
                team={GQLPage.staff_contact}
              />
            )}

            {module.__typename ===
              "GLSTRAPI_ComponentModulesDetailPageModules" && (
              <StaticRichTextBlock module={module} />
            )}

            {module.__typename ===
              "GLSTRAPI_ComponentModulesDetailPageModules" &&
              module.select_module === "book_a_valuation" && (
                <StaticBookValuation module={module} />
              )}

            {module.__typename ===
              "GLSTRAPI_ComponentComponentsSelectFormsModule" &&
              module.select_form === "get_in_touch" && <GetInTouchBlock />}

            {module.__typename ===
              "GLSTRAPI_ComponentComponentsSelectGlobalModules" &&
              (module.Select_Module === "Get_Started_Banner_Module" ||
                module.Select_Module === "Start_Your_Career_Banner_Module") && (
                <FullWidthBanner module={module} />
              )}

            {module.__typename ===
              "GLSTRAPI_ComponentComponentsIconGridItems" && (
              <IconGridModule module={module} />
            )}
            {module.__typename ===
              "GLSTRAPI_ComponentComponentsShowFeaturedProperty" && (
              <FeaturedPropertiesSlider module={module.show} />
            )}

            {module.__typename ===
              "GLSTRAPI_ComponentComponentsSelectGlobalModules" &&
              module.Select_Module === "Team_Slider_Module" && (
                <OurPeopleSlider />
              )}

            {module.__typename ===
              "GLSTRAPI_ComponentComponentsSelectGlobalModules" &&
              module.Select_Module === "News_Slider_Module" && (
                <NewsSlider module={module} />
              )}

            {module.__typename ===
              "GLSTRAPI_ComponentComponentsSelectGlobalModules" &&
              module.Select_Module === "Communities_Listing_Page" && (
                <AreaguideListing />
              )}

            {module.__typename ===
              "GLSTRAPI_ComponentComponentsSelectGlobalModules" &&
              module.Select_Module === "Teams_Listing_Page" && <TeamsListing />}
            {module.__typename ===
              "GLSTRAPI_ComponentComponentsSelectGlobalModules" &&
              module.Select_Module === "News_Listing_Page" && <NewsListing />}
            {module.__typename ===
              "GLSTRAPI_ComponentComponentsSelectGlobalModules" &&
              module.Select_Module === "Careers_Listing_Module" && (
                <CareersListing title={module.Title} />
              )}

            {module.__typename ===
              "GLSTRAPI_ComponentComponentsSelectGlobalModules" &&
              module.Select_Module === "Video_Client_Reviews_Slider" && (
                <TestimonialSlider
                  title={module.Title}
                  description={module.Description}
                  theme={module.Theme}
                />
              )}

            {module.__typename ===
              "GLSTRAPI_ComponentComponentsSelectGlobalModules" &&
              module.Select_Module ===
                "Interviews_Testimonials_Events_Slider" && (
                <TestimonialCategorySlider
                  title={module.Title}
                  description={module.Description}
                  theme={module.Theme}
                />
              )}
          </>
        )
      })}
      <Footer />
    </div>
  )
}

export default CommonTemplate
