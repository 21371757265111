import React, { useState, useEffect } from "react"

import { Form } from "react-bootstrap"

import InputField from './elements/input'
import SelectField from './elements/select'
import TextAreaField from './elements/textarea'
import CheckboxField from './elements/checkbox'
import RadioField from './elements/radio'
import ButtonField from './elements/button'
import HtmlBox from './elements/html'
import ReCaptchaBox from './elements/recaptcha'
import { postFormData } from "./api/Api"
import $ from "jquery"
import { useLocation } from "@reach/router"
import { parse } from "query-string"
import axios from "axios"
import * as qs from "query-string"
import _ from "lodash";
import { getUser } from "@myaccount/website-service"

function MySimpleForm(props) {
  const userData = getUser();
  const [telephone, setTelephone] = useState(userData?.mobile || ``)

  const [startDate, setStartDate] = useState();
  const [startTime, setStartTime] = useState();
  const [validated, setValidated] = useState(false);
  const [showerror, setShowerror] = useState(false);
  const [showthankyou, setThankyou] = useState(false);

  const [formvalues, setFormvalues] = useState("");

  const [token, setToken] = useState("");

  const myRef = React.createRef();

  const recaptchaRef = React.createRef();


  const fields = ([
      {
        element: "config",
        formname: "Property valuation",
        form_type: "contact",
        error_text: "Highlighted fields are required",
        success_text: "Thank you for contacting us. A member of our team will contact you shortly.",
        email_temp_user: "list_my_property_user",
        email_temp_admin: "list_my_property_admin",
        email_subject_user:"Your valuation request",
        email_subject_admin:"Valuation Request",
        email_server_func: "contact",
        event_tracking: "contact",
        page_url: "/general-enquiry"
      },      
      {
        grpmd: "12",
        label: "Full Name",
        placeholder: "",
        name: "name",
        type: "text",
        element: "input",
        class:"form__field",
        required: true,
        patternchk: "^[-a-zA-Z0-9-()]+(\\s+[-a-zA-Z0-9-()]+)*$",
        labelClass: "content_b-18",
        defaultValue: userData?.name || ``
      },
      {
        grpmd: "12",
        label: "Phone",
        placeholder: "",
        name: "telephone",
        type: "telephone",
        element: "input",
        class:"form__field",
        required: true,
        patternchk:"^[+]*[0-9-()]+(\\s+[0-9-()]+)*.{9}$",
        labelClass: "content_b-18",
        defaultValue: userData?.mobile || ``,
        filedwrap:"left-field",

      },  
      {
        grpmd: "12",
        label: "Email Address",
        placeholder: "",
        name: "email",
        type: "email",
        element: "input",
        class:"form__field",
        required: false,
        patternchk:"[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,}$",
        labelClass: "content_b-18",
        filedwrap:"right-field",
        defaultValue: userData?.email || ``
      },
      // {
      //   grpmd: "12",
      //   label: "Property Type",
      //   placeholder: "",
      //   name: "department",
      //   id: "department",
      //   element: "select",
      //   required: true,
      //   type: "select",
      //   class: "select-box",
      //   values: ['Sales', 'Rental', 'Both']
      // },
      {
        grpmd: "12",
        label: "Property Address",
        placeholder: "",
        name: "address",
        type: "text",
        element: "input",
        class:"form__field",
        required: true,
        patternchk: "^[-a-zA-Z0-9-()]+(\\s+[-a-zA-Z0-9-()]+)*$",
        labelClass: "content_b-18"
      }, 
      // {
      //   grpmd: "12",
      //   label: "",
      //   placeholder: "Preferred date",
      //   name: "date",
      //   type: "datetime",
      //   element: "input",
      //   class:"form__field form-control",
      //   // min: today,
      //   // max: maxday.toString(),
      //   required: true,
      //   patternchk: ""
      // }, 
      // {
      //   grpmd: "12",
      //   label: "Message",
      //   placeholder:"Write your message here...",
      //   name: "message",
      //   element: "textarea",
      //   class: "form__field form__field-textarea",
      //   rows:"3",
      //   labelClass: "content_b-18"
      // },  
      {
        grpmd: "12",
        name: "Submit",
        type:"submit",
        element: "button",
        value: "submit",
        labelClass: "content_b-18"
      },
      
      {
        element: "captcha",
        class: "py-2",
        captchaRef: recaptchaRef
      },
    ]);

    const handlechange = event => {
      // remove initial empty spaces
      event.target.value = event.target.value.trimStart()
    }
    const handleselectchange = event => {
      // remove initial empty spaces
      event.value = event.value.trimStart()
    }
  
  useEffect(() => {
    $(".form-group input").blur(function(){
      $(".form-group input").each(function(){
        if ($(this).is(":invalid")) {
          $(this).closest(".form-group").addClass("invalid--field");
        } else {
          $(this).closest(".form-group").removeClass("invalid--field");
        }      
      })
    });
    // $(".select-option.form-group").change(function(){
    //   $(".select-option.form-group input[type=hidden]").each(function(){
    //     if ($(this).val() == "") {
    //       $(this).closest(".form-group").addClass("invalid--field");
    //     } else {
    //       $(this).closest(".form-group").removeClass("invalid--field");
    //     }      
    //   })
    // });

    if (token !== '') {

 const processFromData = async () => {
      
      formvalues['g-recaptcha-response'] = token;
      
      let formData = new FormData();

      // formvalues['extra'] = JSON.stringify({
      //   message: formvalues.message,
      //   name: formvalues.name
      // });
      formvalues['name'] = formvalues.name;
      formvalues['email_subject_user'] = fields[0].email_subject_user;
      formvalues['email_subject_admin'] = fields[0].email_subject_admin;

      formData.append('data', JSON.stringify(formvalues));

      postFormData(formData).then(async apiRes => {
      
      window.grecaptcha.reset()

      // lets send mail
      await window.fetch(`${process.env.GATSBY_CLOUD_URL}/api/form`, {
        method: `POST`,
        mode: "no-cors",
        headers: {
          'Access-Control-Allow-Origin': '*',
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: qs.stringify(formvalues),
      })

      });

      const url = typeof window !== 'undefined' ? window.location.href : ''  
      if (url.indexOf("property") > -1 && fields[4].event_tracking_decider) {
        fields[0].formname = 'Get mortgage help';
      }
      // tracking event
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'formSubmit',
        'formType': 'form-'+fields[0].event_tracking,
        'formId': 'form-'+fields[0].event_tracking,
        'formName': fields[0].formname,
        'formLabel': fields[0].formname
      });

      setShowerror(false);
      setThankyou(true);
      
      myRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })

      }
      processFromData();

    }
  }, [token]);

  const handleonVerify = token => {
    console.log("captcha verified");
    setToken(token);
  };

  const handleSubmit = event => {

    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      $(".form-group input").each(function(){
        if ($(this).is(":invalid")) {
          $(this).closest(".form-group").addClass("invalid--field");
        } else {
          $(this).closest(".form-group").removeClass("invalid--field");
        }      
      })
      // $(".select-option.form-group input[type=hidden]").each(function(){
      //   if ($(this).val() == "") {
      //     $(this).closest(".form-group").addClass("invalid--field");
      //   } else {
      //     $(this).closest(".form-group").removeClass("invalid--field");
      //   }      
      // })
      
      event.preventDefault();
      event.stopPropagation();
      setShowerror(true);
      setValidated(true);
      setThankyou(false);
      myRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
    else {

      event.preventDefault();

      const formsdata = (event.target);
      const json = {}
      Object.keys(formsdata).map(key => (
        json[formsdata[key].name] = (formsdata[key].checked) ? 'yes' : formsdata[key].value
      ))

      json['email_temp_user'] = fields[0].email_temp_user;
      json['email_temp_admin'] = fields[0].email_temp_admin;
      json['formname'] = fields[0].formname;
      json['g-recaptcha-response'] = token;
json['extra'] = _.omit(json, ["formname", "name", "email", "telephone"])



      setFormvalues(json);

      recaptchaRef.current.execute();

      setValidated(false);

      // reset form
      const form = event.target
      form.reset();
      setStartDate();
      setStartTime();
      setTelephone("");
    }
  };

  return (
    <div className="form stbform common_form_class" id="contactform">
    <div ref={myRef} />

    <div className="form-wraps input-wraps">
      
    {showerror && <div className="alert-error">
      <p>{fields[0].error_text}</p>
    </div>}

    {showthankyou && <div className="alert-success">
      <p>{fields[0].success_text}</p>
    </div>}

      <Form className="contact-form form-w-label enquiry-form" name={fields[0].formname} action="/thank-you/" method="post" noValidate validated={validated} onSubmit={handleSubmit}>
        <input type="hidden" name="form_name" value={fields[0].formname} />
        <input type="hidden" name="form_type" value={fields[0].form_type} />
        <input type="hidden" name="to_email_id" value={props.to_email_id} />
        <input type="hidden" name="bot-field" />
        
        {fields.map((field, index) => {
            if ( "input" === field.element ) {
              return (
                <InputField
                  name={field.name}
                  grpmd={field.grpmd}
                  ref={field.ref}
                  type={field.type}
                  fieldClass={field.class}
                  filedwrap={field.filedwrap}
                  placeholder={field.placeholder}
                  label={field.label}
                  labelClass={field.labelClass}
                  required={field.required}
                  key={`${field.element}~${index}`}
                  pattern={field.patternchk}
                  handlechange={handlechange}
                  defaultValue={field?.defaultValue}
                  startDate={field.type === 'datetime' ? startDate : ''}
                  setStartDate={field.type === 'datetime' ? setStartDate : ''}
                  startTime={field.type === 'datetime' ? startTime : ''}
                  setStartTime={field.type === 'datetime' ? setStartTime : ''}
                  setTelephone={setTelephone}
                  telephone={telephone}
                />
              );
            }
            if ("select" === field.element) {
              return (
                <SelectField
                  name={field.name}
                  filedwrap={field.filedwrap}
                  grpmd={field.grpmd}
                  label={field.label}
                  ref={field.ref}
                  required={field.required}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  values={field.values}
                  key={`${field.element}~${index}`}
                  handlechange={handleselectchange}
                  componentprops={props}
                />
              );
            }
            if ("textarea" === field.element) {
              return (
                <TextAreaField
                  name={field.name}
                  grpmd={field.grpmd}
                  ref={field.ref}
                  rows={field.rows}
                  fieldClass={field.class}
                  label={field.label}
                  labelClass={field.labelClass}
                  placeholder={field.placeholder}
                  required={field.required}
                  key={`${field.element}~${index}`}
                  handlechange={handlechange}
                />
              );
            }
            if ("checkbox" === field.element) {
              return (
                <CheckboxField
                  name={field.name}
                  ref={field.ref}
                  value={field.value}
                  label={field.label}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  required={field.required}
                  key={`${field.name}~${index}`}
                  handlechange={handlechange}
                />
              );
            }
            if ("radio" === field.element) {
              return (
                <RadioField
                  name={field.name}
                  ref={field.ref}
                  value={field.value}
                  fieldClass={field.class}
                  checked={field.checked}
                  placeholder={field.placeholder}
                  required={field.required}
                  key={`${field.name}~${index}`}
                  handlechange={handlechange}
                  lastchild={field.lastchild}
                />
              );
            }
            if ("html" === field.element) {
              return (
                <HtmlBox
                  text={field.text}
                  fieldClass={field.class}
                  key={`${field.element}~${index}`}
                />
              );
            }
            if ("captcha" === field.element) {
              return (
                <ReCaptchaBox
                  fieldClass={field.class}
                  captRef={field.captchaRef}
                  key={`${field.element}~${index}`}
                  handleonVerify={handleonVerify}
                />
              );
            }
            if ("button" === field.element) {
              return (
                <ButtonField
                  name={field.name}
                  fieldClass={field.class}
                  type={field.type}
                  value={field.value}
                  key={`${field.element}~${index}`}
                />
              );
            }
          })
        }      
      </Form>
      </div>
    </div>
  );
}


const ContactForm = (props) => (
  <MySimpleForm to_email_id={props.to_email_id} subject={props.subject} staff_name={props.staff_name} formtype={props.formtype} messagelabel={props.messagelabel} messageplaceholder={props.messageplaceholder} />
)

export default ContactForm
