import { Link } from "gatsby"
import React from "react"
import Button from "react-bootstrap/Button"
import Modal from "react-bootstrap/Modal"
import Select from "react-select"
import JobForm from "../forms/career-form-landing"
import "./EnquireModal.scss"

const JobApplyModal = props => {
  const handleSubmit = e => {
    e.preventDefault()
  }

  const selectvalues = [
    {
      label: "General Enquiry",
      value: "General Enquiry",
    },
    {
      label: "Others",
      value: "Others",
    },
  ]
  return (
    <div className="enquire-wrapper">
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="enquire-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h3 className="title">Apply for this role</h3>
            {/* <p className="description">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec facilisis porta lorem, ac dictum urna mattis estibulum eget.
            </p> */}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <JobForm jobname={props.jobname}/>
          {/* <form className="enquiry-form " onSubmit={e => handleSubmit(e)}>
            <div className="input-box">
              <label htmlFor="name">Full Name: *</label>
              <input type="text" name="name" />
            </div>
            <div className="enquiry-form-row">
              <div className="input-box">
                <label htmlFor="phone">Phone: *</label>
                <input type="number" name="phone" />
              </div>
              <div className="input-box">
                <label htmlFor="email">Email Address</label>
                <input type="email" name="email" />
              </div>
            </div>
            <div className="input-box">
              <label htmlFor="subject">Subject:</label>
              <Select
                options={selectvalues}
                isSearchable={false}
                classNamePrefix={"select-opt"}
                className="select-control"
                defaultValue={{
                  label: selectvalues.length > 0 && selectvalues[0].label,
                  value: selectvalues.length > 0 && selectvalues[0].value,
                }}
                onChange={e => {}}
              />
            </div>
            <div className="input-box">
              <label htmlFor="message">Message:</label>
              <textarea type="text" name="message" />
            </div>
            <button type="submit" className="btn btn-black">
              submit details
            </button>
            <div className="terms">
              <p>
                By clicking Submit you agree to our{" "}
                <Link to="/terms-and-conditions">Terms</Link> and{" "}
                <Link to="/privacy-policy">Privacy Policy</Link>.
              </p>
              <p className="required">* required field</p>
            </div>
          </form> */}
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default JobApplyModal
